var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-dialog',{attrs:{"id":"compare-dialog","modal-class":"new-shipment-quotation-compare-dialog","max-width":"774px","hide-header":"","hide-footer":"","persistent":""},model:{value:(_vm.dialogValue),callback:function ($$v) {_vm.dialogValue=$$v},expression:"dialogValue"}},[_c('div',{staticClass:"tw-p-4 md:tw-p-10"},[_c('h1',{staticClass:"new-shipment-quotation-compare-dialog__title tw-relative tw-text-2xl tw-font-medium tw-mb-12 tw-mr-2",domProps:{"textContent":_vm._s(_vm.$t(_vm.getQuotation && _vm.getQuotation.prices && _vm.getQuotation.prices.length > 1
        ? 'shipments.titles.price_compare'
        : 'shipments.titles.price_understanding'))}}),_c('ctk-dialog-close-button',{staticClass:"tw-top-0 tw-right-0",nativeOn:{"click":function($event){_vm.dialogValue = false}}}),_c('div',{staticClass:"new-shipment-quotation-compare-dialog__table tw-mb-5 tw-flex tw-flex-col md:tw-flex-row md:tw-flex-wrap"},[_vm._l((_vm.prices),function(price,k,idx){return [_c('div',{key:(k + "-head"),staticClass:"new-shipment-quotation-compare-dialog__table__head tw-flex tw-items-center tw-text-white tw-text-lg tw-text-center tw-bg-blue-500 tw-order-0 tw-rounded-tl tw-rounded-tr",class:[
            idx ? 'md:tw-mr-0' : 'md:tw-mr-5',
            _vm.isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__head--disabled'
          ]},[_c('div',{staticClass:"tw-mx-auto tw-flex tw-flex-col"},[_c('span',{domProps:{"textContent":_vm._s(_vm.offers[k])}}),(k === 'pallet_network')?_c('span',{staticClass:"tw-text-sm",domProps:{"textContent":_vm._s(("(" + (_vm.$t('new-shipment.titles.pallet_network_meaning')) + ")"))}}):_vm._e()])]),(_vm.hasPrice)?_c('div',{key:(k + "-price"),staticClass:"new-shipment-quotation-compare-dialog__table__price tw-bg-white tw-text-blue-500 tw-order-1",class:[
            idx ? 'md:tw-mr-0' : 'md:tw-mr-5',
            _vm.isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__price--disabled'
          ]},[_c('div',{staticClass:"tw-flex tw-flex-col tw-justify-center tw-m-auto tw-text-center tw-py-8"},[(_vm.getPrice(k))?[_c('span',{staticClass:"tw-font-bold tw-text-xl",domProps:{"textContent":_vm._s(_vm.$t('price_excl_tax', {
                  price: _vm.$options.filters.currency(_vm.getPrice(k).total, _vm.getPrice(k).currency, _vm.$i18n.locale)
                }))}}),_c('span',{staticClass:"tw-text-sm tw-italic",domProps:{"textContent":_vm._s(_vm.$t('price_incl_tax', {
                  price: _vm.$options.filters.currency(_vm.getPrice(k).total_including_vat, _vm.getPrice(k).currency, _vm.$i18n.locale)
                }))}})]:[_c('span',{staticClass:"tw-text-sm tw-px-4",domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.no_price'))}})]],2)]):_vm._e(),_vm._l((_vm.formattedPrice(price)),function(section){return [_c('div',{key:(k + "-" + (section.name) + "-header"),staticClass:"new-shipment-quotation-compare-dialog__table__subheader tw-flex tw-items-center tw-text-center tw-text-blue-500 tw-font-bold",class:[
              idx ? 'md:tw-mr-0' : 'md:tw-mr-5',
              _vm.isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__subheader--disabled'
            ],style:(("order: " + (section.order) + ";"))},[_c('span',{staticClass:"tw-mx-auto",domProps:{"textContent":_vm._s(_vm.$t(section.name))}})]),_vm._l((section.items),function(item,item_index){return _c('div',{key:(k + "-" + (section.name) + "-" + (item.value)),staticClass:"new-shipment-quotation-compare-dialog__table__items tw-flex tw-px-4 tw-bg-white",class:[
              idx ? 'md:tw-mr-0' : 'md:tw-mr-5',
              item_index === 0 && 'tw-pt-5',
              _vm.isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__items--disabled'
            ],style:(("order: " + (item.order) + ";"))},[_c('div',{staticClass:"tw-w-5 tw-mr-3"},[_c('div',{staticClass:"new-shipment-quotation-compare-dialog__table__items__check tw-flex tw-items-center tw-w-4 tw-h-4 tw-rounded-full tw-bg-blue-500 tw-text-white tw-select-none tw-mt-1"},[_c('ui-material-icon',{staticClass:"tw-text-xs tw-m-auto",attrs:{"name":"check"}})],1)]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t(item.value))}}),(item.value === 'shipments.paragraphs.price_compare.load_typology.pallets')?[_c('p',{staticClass:"tw-mb-1",domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.load_typology.accepted_formats'))}}),_c('ul',{staticClass:"tw-p-0 tw-list-none"},[_c('li',{staticClass:"tw-mb-1"},[_c('div',{domProps:{"textContent":_vm._s(("- " + (_vm.$tc('app.values.pallet', 4, {
                        count: _vm.$n(4)
                      })) + " 120x100*"))}}),_c('div',{domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.load_typology.max_weight_total'))}})]),_c('li',{staticClass:"tw-mb-1"},[_c('div',{domProps:{"textContent":_vm._s(("- " + (_vm.$tc('app.values.pallet', 6, {
                        count: _vm.$n(6)
                      })) + " 120x80*"))}}),_c('div',{domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.load_typology.max_weight_total'))}})]),_c('li',[_c('div',{domProps:{"textContent":_vm._s(("- " + (_vm.$tc('app.values.pallet', 12, {
                        count: _vm.$n(12)
                      })) + " 60x80"))}}),_c('div',{domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.load_typology.max_weight'))}})])]),_c('p',{domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.load_typology.pallets_de_be'))}})]:_vm._e()],2)])})]}),_c('div',{key:(k + "-footer"),staticClass:"new-shipment-quotation-compare-dialog__table__footer tw-flex tw-items-center tw-pb-8 tw-pt-4 tw-bg-white tw-rounded-bl tw-rounded-br tw-mb-8 md:tw-mb-0",class:[
            idx ? 'md:tw-mr-0' : 'md:tw-mr-5',
            _vm.isPriceDisabled(k) && 'new-shipment-quotation-compare-dialog__table__footer--disabled'
          ],staticStyle:{"order":"24"}},[(_vm.hasActions)?[(_vm.hasPrice)?[(!_vm.isPriceDisabled(k) && _vm.getQuotation && _vm.getQuotation.prices && _vm.getQuotation.prices.length > 1)?_c('ui-button',{staticClass:"tw-text-white tw-rounded-full tw-mx-auto tw-px-4",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){return _vm.selectPrice(k)}}},[_vm._v(" "+_vm._s(_vm.$t('app.buttons.select'))+" ")]):_vm._e()]:_c('ui-button',{directives:[{name:"matomo",rawName:"v-matomo",value:({
                click: { category: 'Dashboard', action: 'Click New Shipment', name: k }
              }),expression:"{\n                click: { category: 'Dashboard', action: 'Click New Shipment', name: k }\n              }"}],staticClass:"tw-text-white tw-rounded-full tw-mx-auto tw-px-4",attrs:{"to":{
                name: 'NewShipment'
              },"variant":"primary"},nativeOn:{"click":function($event){_vm.dialogValue = false}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('navigation.new-shipment')))+" ")])]:_vm._e()],2)]})],2),_c('ul',{staticClass:"new-shipment-quotation-compare-dialog__notes tw-text-xs tw-mb-5 tw-list-none tw-p-0"},[_c('li',{staticClass:"tw-mb-1",domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.countries', {
          countries_list: _vm.countries
        }))}}),_c('li',{staticClass:"tw-mb-1",domProps:{"textContent":_vm._s(_vm.$t('shipments.paragraphs.price_compare.fragile_load'))}})]),_c('new-shipment-quotation-compare-dialog-contact')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }